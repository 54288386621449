import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    buildings: Array,
    apiKey: String
  }

  connect() {
    // TODO: 初期表示どうするか要検討
    // SEE: https://rmap.ekispert.jp/production/doc/document.html
    this.rosen = new Rosen(this.element, {
      apiKey: this.apiKeyValue,
      zoom: 16
    });

    this.buildingsValue.forEach((building) => {
      const popupContent = `
        <a href="${building.url}">
          <img alt="${building.name}" src="${building.image_url}">
          <div class="text">
            <h3>${building.name}</h3>
            <p>${building.key_phrase}</p>
          </div>
        </a>
      `;

      const popup = Rosen.htmlPopup({
        closeButton: false
      }).setHTML(popupContent);
      this.rosen.setStationPopup(building.ekispert_station_code, popup, false)
        .then((marker) => {
          marker.closePopup()
        });
    })
  }
}
