import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

type StationOption = {
  code: string
  name: string
}

declare global {
  interface HTMLSelectElement {
    tomselect: TomSelect;
  }
}

export default class extends Controller {
  static targets = ['select']
  static values = { apiKey: String }

  declare readonly selectTarget: HTMLSelectElement
  declare readonly apiKeyValue: string
  connect(): void {
    const apiKey = this.apiKeyValue

    new TomSelect(this.selectTarget, {
      valueField: 'name',
      labelField: 'name',
      searchField: [], // ひらがな・英語での入力でもオートコンプリートできるように
      onType: (): void => { this.selectTarget.tomselect.clear() }, // ２つ以上駅名を入力できないように
      optionClass: 'commute-search-form__station-option',
      itemClass: 'commute-search-form__station-item',
      loadThrottle: 500,
      load: (query: string, callback: (options?: StationOption[]) => void): void => {
        if (!query.length) return callback()
        this.selectTarget.tomselect.clearOptions() // 以前にAJAXして取得したオプションが残ってしまうので
        const url = `https://api.ekispert.jp/v1/json/station/light?key=${apiKey}&type=train&name=` + encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(json => {
            if (json.ResultSet && json.ResultSet.Point) {
              // 検索結果が一件の時、駅すぱあとが配列を返さないので
              const points = Array.isArray(json.ResultSet.Point) ? json.ResultSet.Point : [json.ResultSet.Point]

              const options: StationOption[] = points.map((point: { Station: { code: string, Name: string } }) => ({
                code: point.Station.code,
                name: point.Station.Name,
              }))
              callback(options)
            } else {
              callback()
            }
          })
          .catch(error => {
            console.error(error)
            callback()
          })
      }
    })
  }
}
